import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Tjøme and Nøtterøy`}</h4>
    <p>{`Last weekend we headed off to the islands of Tjøme and Nøtterøy in the
Færder municipality south-west of Oslo, Norway. These islands have a very dense
network of trails criss-crossing all over the islands and can entertain you for
days. But they can be hard to find and especially navigate due to the amount of
trail crossings you keep on meeting.`}</p>
    <Image src="/news/tjome/verdensende.png" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  The rock slabs at Verdens Ende at the tip of Tjøme
    </p>
    <p>{`The plan was to get lots of great biking done while at the same time test
trailguide mounted on the handle-bars to see how we could make the app help
you with live navigation while riding your bike in such a trail network.`}</p>
    <Image src="/news/tjome/packing.png" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  Rental car filled with all the gear needed for three days
    </p>
    <h4>{`Navigation problems`}</h4>
    <p>{`The two islands of Tjøme and Nøtterøy is full of different trails going through
open woods, deep woods, canyons, medows, over hills and ridges, and onto
slab rock with super views over the archipelago.`}</p>
    <br />
    <p>{`There is always another trail going off in another direction every 100 meters,
so it was clear that simply having trailguide on the handle bars was not enough.
The buttons were too small and it was cumbersome having to click to find your
location all the time.`}</p>
    <Image src="/news/tjome/flowerbed.png" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  These white flowers show up all over the forest floor in May
    </p>
    <Image src="/news/tjome/canyon.png" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  There are lots of these canyons made during the last ice age
    </p>
    <h4>{`Adjusting the user interface`}</h4>
    <p>{`Before the others got up I got some programming in to try to find a solution
to some of the problems we experienced the day before. In the short time I
mostly got the user interface adjusted so it could be configured to better
handle glows while riding the bike.`}</p>
    <br />
    <p>{`So after a heavy breakfast designed to last most of the day, we were off into
the sun riding for another six hours on these amazing islands.`}</p>
    <Image src="/news/tjome/climbs.png" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  One of many, many, many climbs on Nøtterøy. Those e-bikes were handy.
    </p>
    <h4>{`Adding a navigation mode`}</h4>
    <p>{`Having thought up a solution I think could work, and that would not be too
hard to implement, I got up before the others again and started coding. Within
half an hour I had a prototype up, mostly kept together with ducttape and string,
and ran around checking if the dot would follow me. It did!`}</p>
    <Image src="/news/tjome/code.png" className="mx-auto mt-12" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  How coffe and pizza is turned into a trail app through code
    </p>
    <div className="flex flex-wrap justify-center mb-12">
  <Image src="/news/tjome/phonesettings.png" className="w-1/3 mx-auto" mdxType="Image" />
  <Image src="/news/tjome/phonedot.png" className="w-1/3 mx-auto" mdxType="Image" />
    </div>
    <h4>{`Navigation mode is a success`}</h4>
    <p>{`We could not wait to test out our new solution, so in addition to my two friends
with their Quad Lock solutions, I also strapped my phone onto the bike for some
live testing.`}</p>
    <Image src="/news/tjome/trailguide.png" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  Professional test harness for fresh software
    </p>
    <p>{`The prototype worked really well, and it took us through the day almost without
stopping at a single trail crossing. We knew were to go simply by glancing at
the phone and see our dot dutifully following us.`}</p>
    <Image src="/news/tjome/ocean.png" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  The end of the trail. Denmark is next.
    </p>
    <Image src="/news/tjome/food.png" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  Luckily we could camp just beside the cabin of friends with a grill
    </p>
    <Image src="/news/tjome/sleeping.png" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-xs mb-16 text-center">
  Sleeping quarters for happy trail hunters
    </p>
    <p>{`So the weekend was over, the prototype was a success, biking amazing, and we
headed home for a day of replacing the ducttape and strings with proper test
code and quality harnesses.`}</p>
    <p>{`You can find the navigation mode from the settings menu.`}</p>
    <br />
With kind regards,
    <br />
Bjørn Jarle
    <br />
Developer in Chief

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      